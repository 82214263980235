<template>
    <div class="section-padding">
        <div class="container">
            <div class="row">
                <div class="col-md-6 align-self-center">
                    <p class="text__header">
                        <span class="text__gradient" :style="{ paddingRight: '0.5px' }">Buy</span>,
                        <span class="text__gradient">Sell</span> &
                        <span class="text__gradient">Convert</span> your Local Currency
                    </p>
                    <p>
                        With <span class="text__gradient"><b>{{ appName }}</b></span>, you can send Naira to anywhere in
                        Nigeria from any country in the world directly into your {{ appName }} wallet.
                    </p>
                    <div class="my-4">
                        <a class="btn btn__primary" href="#signup">Create an Account</a>
                    </div>
                    <p class="fw-bold">
                        {{ isSell ? 'Sell' : 'Buy' }} using top payment methods
                    </p>
                    <div>
                        <a href="#" class="btn btn__gray-outline">
                            Fincra
                        </a>
                        <a href="#" class="btn btn__gray-outline mx-3">
                            KoraPay
                        </a>
                        <a href="#" class="btn btn__gray-outline">
                            Interac
                        </a>
                    </div>
                </div>
                <div class="col-md-6 text-md-end mt-4 mt-md-0">
                    <img src="@/assets/images/herobanner.png" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {appName} from "@/utils/variables";
export default {
    name: "HeroBanner",
    props: {
        isSell: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return { appName }
    }
}
</script>

<style scoped>

</style>
