<script>
import stateData from "@/data/states.json";
import {requiredField, validateMoneyFormat} from '@/utils/validation';

export default {
    name: "HomeInfo",
    props: {
        form: {
            type: Object
        }
    },
    data() {
        return {
            states: stateData,
            homeUse: [
                {value: 'personal', label: 'For personal use'},
                {value: 'renting', label: 'For renting'},
                {value: 'holiday', label: 'Holiday home'}
            ],
            homeType: [
                '2 bedroom Terrace duplex',
                '2 bedroom Terrace duplex + BQ',
                '4 bedroom Terrace duplex',
                '3 bedroom Terrace duplex',
                '1 bedroom apartment',
                '2 bedroom apartment',
                '4 bedroom Semi-detached duplex + BQ',
                '4 bedroom Semi-detached duplex + BQ + private pool',
                '3 bedroom Terrace duplex + BQ'
            ],
            fieldErrors: {
                min_price: '',
                max_price: '',
                home_use: '',
                state: '',
                home_type: '',
                min_down_pay: '',
                max_down_pay: '',
            },
        }
    },
    computed: {
        selectedStateLocations() {
            const selectedStateData = this.states.find((state) => {
                this.form.location = '';
                return state.name === this.form.state
            });
            return selectedStateData ? selectedStateData.locations : [];
        },
    },
    methods: {
        validateForm() {
            if (
                this.requiredField(this.form.home_type, 'home_type') &&
                this.requiredField(this.form.home_use, 'home_use') &&
                this.requiredField(this.form.max_price, 'max_price') &&
                this.requiredField(this.form.min_price, 'min_price') &&
                this.requiredField(this.form.max_down_pay, 'max_down_pay') &&
                this.requiredField(this.form.min_down_pay, 'min_down_pay') &&
                this.requiredField(this.form.state, 'state')
            ) {
                this.nextTab();
            }

            this.requiredField(this.form.home_type, 'home_type');
            this.requiredField(this.form.home_use, 'home_use');
            this.validateMoneyInput(this.form.max_price, 'max_price');
            this.validateMoneyInput(this.form.min_price, 'min_price');
            this.validateMoneyInput(this.form.max_down_pay, 'max_down_pay');
            this.validateMoneyInput(this.form.min_down_pay, 'min_down_pay');
            this.requiredField(this.form.state, 'state');
        },
        nextTab() {
            this.$emit('next', 'next')
        },
        previousTab() {
            this.$emit('previous', 'previous')
        },
        requiredField(value, fieldName) {
            if (requiredField(value)) {
                this.fieldErrors[fieldName] = '';
                return true;
            } else {
                this.fieldErrors[fieldName] = 'This field is required.';
                return false;
            }
        },
        validateMoneyInput(value, fieldName) {
            if (validateMoneyFormat(value)) {
                this.fieldErrors[fieldName] = '';
                return true;
            } else {
                this.fieldErrors[fieldName] = 'Please enter a valid amount';
                return false;
            }
        }
    }
}
</script>

<template>
    <div class="col-md-9 col-lg-8">
        <div class="row">
            <div class="col-md-6">
                <div class="form-input">
                    <select v-model="form.home_use" required >
                        <option value="">How will you use the home?</option>
                        <option v-for="item in homeUse" :key="item.value">{{ item.label }}</option>
                    </select>
                    <span class="text-danger fs-11" v-if="fieldErrors.home_use">{{ fieldErrors.home_use }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-input">
                    <select v-model="form.state" required >
                        <option value="">In what state are you looking?</option>
                        <option v-for="state in states" :key="state.name">{{ state.name }}</option>
                    </select>
                    <span class="text-danger fs-11" v-if="fieldErrors.state">{{ fieldErrors.state }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-input">
                    <select v-model="form.home_type" required >
                        <option value="">Type of home</option>
                        <option v-for="item in homeType" :key="item">{{ item }}</option>
                    </select>
                    <span class="text-danger fs-11" v-if="fieldErrors.home_type">{{ fieldErrors.home_type }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-input">
                    <select v-model="form.location">
                        <option value="">Location</option>
                        <option v-for="location in selectedStateLocations" :key="location">{{ location }}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-input">
                    <input placeholder="Minimum price (Naira) *"
                           type="text"
                           v-model="form.min_price"
                           @input="validateMoneyInput(form.min_price, 'min_price')"
                           required>
                    <span class="text-danger fs-11" v-if="fieldErrors.min_price">{{ fieldErrors.min_price }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-input">
                    <input placeholder="Maximum price (Naira) *"
                           type="text"
                           v-model="form.max_price"
                           @input="validateMoneyInput(form.max_price, 'max_price')"
                           required>
                    <span class="text-danger fs-11" v-if="fieldErrors.max_price">{{ fieldErrors.max_price }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-input">
                    <input placeholder="Minimum down payment (Naira) *"
                           type="text"
                           v-model="form.min_down_pay"
                           @input="validateMoneyInput(form.min_down_pay, 'min_down_pay')"
                           required>
                    <span class="text-danger fs-11" v-if="fieldErrors.min_down_pay">{{ fieldErrors.min_down_pay }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-input">
                    <input placeholder="Maximum down payment (Naira) *"
                           type="text"
                           v-model="form.max_down_pay"
                           @input="validateMoneyInput(form.max_down_pay, 'max_down_pay')"
                           required>
                    <span class="text-danger fs-11" v-if="fieldErrors.max_down_pay">{{ fieldErrors.max_down_pay }}</span>
                </div>
            </div>

            <div class="d-flex justify-content-between mt-5">
                <div class="arrow-icon">
                    <button class="btn" @click="previousTab">
                        <i class="bi bi-arrow-left-short fs-3 v-align_inherit"></i>
                    </button>
                </div>
                <div class="text-end">
                    <button type="button" class="btn btn__primary" @click="validateForm">
                        Submit
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>