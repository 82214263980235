<template>
    <div>
        <div class="card card__market mt-4" v-for="(item, index) in items" :key="index" >
            <div class="row">
                <div class="col-6 col-md-3">
                    <h6>
                        <span :class="isSell ? 'text-danger' : 'text-success'">{{ item.amount}}</span>
                        <span> NGN/CAD</span>
                    </h6>
                    <small>Available amount: {{ item.available_amount }} CAD</small>
                </div>
                <div class="col-6 col-md-3">
                    <h6 class="fw-bold">{{ item.payment_method }}</h6>
                    <small>Completion time: ~0s</small>
                </div>
                <div class="col-6 col-md-3 mt-4 mt-md-0">
                    <i class="bi bi-dot text-success align-middle fs-2"></i><span>{{ item.user}}</span>
                </div>
                <div class="col-6 col-md-3 mt-4 mt-md-0 align-self-center">
                    <a href="#signup" class="btn btn__gray-outline" v-if="isSell">Sell</a>
                    <a href="#signup" class="btn btn__gray-outline" v-else>Buy</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MarketChart",
    props: {
        items: {
            type: Array
        },
        isSell: {
            type: Boolean,
            default: true
        }
    }
}
</script>
