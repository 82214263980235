<template>
    <div class="wrapper">
        <!-- Header -->
        <Header/>
        <!-- End Header -->
        <div class="faq-wrapper bg-gradient">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 text-left">
                        <h2 class="text-white">Our Blog</h2>
                        <p class="text-white">
                            Read our customer stories and learn more about {{ appName }}.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-padding">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 mb-4" v-for="(post, index) in posts" :key="index">
                        <div class="card card-no-border card__bg-gray">
                            <div class="row">
                                <div class="col-md-4 mb-4 mb-md-0">
                                    <img :src="post.thumbnail" class="img-fluid">
                                </div>
                                <div class="col-md-8">
                                    <label class="fs-11">{{ new Date(post.pubDate).toDateString() }}</label>
                                    <p class="fw-600 text__black fs-20">
                                        {{ post.title }}
                                    </p>
                                    <a class="btn btn__primary" :href="post.link" target="_blank">Read more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- App Store -->
        <AppStore/>
        <!-- End App Store -->
        <!-- Footer -->
        <Footer/>
        <!-- End Footer -->
    </div>
</template>

<script>
import Header from "@/components/elements/Header";
import Footer from "@/components/elements/Footer";
import AppStore from "@/components/AppStore";
import axios from "axios";
import {appName} from "@/utils/variables";

export default {
    name: "Blog",
    components: {Header, Footer, AppStore},
    data() {
        return {
            posts: [],
            appName
        }
    },
    methods: {
        async getBlogPost() {
            try {
                const mediumRssFeed = "https://medium.com/feed/@sokorx";
                const rssToJsonApi = "https://api.rss2json.com/v1/api.json";
                const data = {params: {rss_url: mediumRssFeed}};
                axios.get(rssToJsonApi, data)
                    .then(response => {
                        this.posts = response.data.items
                    })
                    .catch(error => {
                        console.log(error)
                    })
            } catch (error) {
                console.error(error);
            }
        }
    },
    beforeMount() {
        this.getBlogPost()
    }
}
</script>

<style scoped>

</style>