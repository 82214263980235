<template>
    <section class="footer bg-light">
        <div class="container">
            <div class="row footer-first m-0">
                <div class="col-md-3 col-lg-2 mb-5 mb-md-0">
                    <a href="/">
                        <img src="@/assets/images/logo.png" alt="Logo" class="d-inline-block align-text-top">
                    </a>
                </div>
                <div class="col-md-4 mb-5 mb-md-0">
                    <p class="mb-4">
                        Most secured peer-to-peer platform for you currency exchange transactions
                    </p>
                    <p>Security as a Lifestyle</p>
                </div>
                <div class="col-md-2 mt-5 mt-md-0">
                    <p class="text__black">Quick Links</p>
                    <div class="d-flex d-md-block flex-wrap flex-md-nowrap">
                        <p class="cursor-pointer pe-4">
                            <router-link to="/" class="nav-link text__gray">Home</router-link>
                        </p>
                        <p class="cursor-pointer pe-4">
                            <router-link to="/faq" class="nav-link text__gray">FAQs</router-link>
                        </p>
                        <p class="cursor-pointer pe-4">
                            <router-link to="/contact" class="nav-link text__gray">Contact us</router-link>
                        </p>
                        <p class="cursor-pointer pe-4">
                            <router-link to="/blogs" class="nav-link text__gray">Blogs</router-link>
                        </p>
                    </div>
                </div>
                <div class="col-md-3">
                    <p class="text__black">Connect with us</p>
                    <div class="footer-socials">
                        <a href="#" class="fs-24 pe-3"><i class="bi bi-facebook"></i></a>
                        <a href="#" class="fs-24 pe-3"><i class="bi bi-twitter-x"></i></a>
                        <a href="#" class="fs-24"><i class="bi bi-instagram"></i></a>
                    </div>
                   <!-- <div class="d-flex mt-4">
                        <i class="bi bi-telephone me-3"></i>
                        <div>+1 14372195867</div>
                    </div> -->
                    <div class="d-flex mt-4">
                        <i class="bi bi-envelope me-3"></i>
                        <a :href="'mailto:' + businessEmail">{{businessEmail}}</a>
                    </div>
                </div>
            </div>
            <div class="d-md-flex justify-content-md-between footer-sub">
                <div class="text-center text-md-start">
                    <span class="me-3">
                        © {{ new Date().getFullYear() }}
                        <span class="text__gradient">{{ appName }}</span> Limited. All rights reserved.
                    </span>
                </div>
                <div class="mt-4 mt-md-0 text-center text-md-start">
                    <span class="me-3"><router-link to="/terms-conditions">Terms and Conditions </router-link></span>
                    <span><router-link to="/privacy-policy">Privacy Policy</router-link></span>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {appName, businessEmail} from "@/utils/variables";

export default {
    name: "Footer",
    data() {
        return {appName, businessEmail}
    }
}
</script>
