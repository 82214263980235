<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>

export default {
    name: 'App',
    components: {
    }
}
</script>

<style lang="scss">
@import "assets/scss/styles";
</style>
