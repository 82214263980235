<template>
    <div class="d-flex align-items-center text--primary justify-content-center ht-100vh">
        <div class="text-center">
            <img src="@/assets/images/404.png" alt="page not found">
            <span class="fw-bold fs-48 d-block text__black">Page Not Found</span>
            <span class="d-block">
                This page does not exist. Click <a class="text__gradient mx-1" href="/">here</a>to return to Homepage
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "Error404"
}
</script>

<style scoped>

</style>