import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "../views/Home";
import Contact from "@/views/Contact";
import Faq from "@/views/Faq";
import Blog from "@/views/Blog";
import Error404 from "@/views/Error404";
import TermsAndCondition from "@/views/TermsAndCondition";
import PrivacyPolicy from "@/views/PrivacyPolicy";
import Mortgage from "@/views/Mortgage.vue";
import MortgageApply from "@/views/MortgageApply.vue";

Vue.use(VueRouter);

const routes = [
	{path: '/', name: 'Home', component: Home},
	{path: '/contact', name: 'Contact', component: Contact},
	{path: '/faq', name: 'Faq', component: Faq},
	{path: '/blogs', name: 'Blog', component: Blog},
	{path: '/terms-conditions', name: 'TermsAndCondition', component: TermsAndCondition},
	{path: '/privacy-policy', name: 'PrivacyPolicy', component: PrivacyPolicy},
	{path: '/mortgage', name: 'Mortgage', component: Mortgage},
	{path: '/mortgage/apply', name: 'MortgageApply', component: MortgageApply},
	{path: '/*', name: 'Page404', component: Error404},
];

const router = new VueRouter({
	routes,
	mode: 'history'
});

export default router