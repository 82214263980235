import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import "bootstrap-icons/font/bootstrap-icons.css"
import 'bootstrap/js/dist/collapse';
import VueCompositionAPI from '@vue/composition-api'

Vue.use(VueCompositionAPI)
Vue.config.productionTip = false

export const bus = new Vue()

new Vue({
  router,
  render: h => h(App),
  store: store
}).$mount('#app')
