import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

import authentication from "./modules/authentication.js"

const store = {
    modules: {
        authentication
    }
}

export default new Vuex.Store(store)