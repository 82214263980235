<script>

import Footer from "@/components/elements/Footer.vue";
import Header from "@/components/elements/Header.vue";
import PersonalInfo from "@/components/forms/PersonalInfo.vue";
import HomeInfo from "@/components/forms/HomeInfo.vue";
import SuccessPage from "@/components/SuccessPage.vue";

export default {
    name: "MortgageApply",
    components: {Footer, Header, PersonalInfo, HomeInfo, SuccessPage},
    data() {
        return {
            formData: {
                last_name: '',
                first_name: '',
                city: '',
                canada_address_1: '',
                canada_address_2: '',
                postal_code: '',
                phone_number: '',
                gross_annual_income: '',
                net_annual_income: '',
                monthly_net_pay: '',
                other_annual_income: '',
                min_price: '',
                max_price: '',
                min_down_pay: '',
                max_down_pay: '',
                is_self_employed: '',
                dob: '',
                marital_status: '',
                home_use: '',
                state: '',
                home_type: '',
                location: '',
                sin: ''
            },
            currentTab: 'PersonalInfo'
        }
    },
    methods: {
        nextTab() {
            if (this.currentTab === 'PersonalInfo') {
                this.currentTab = 'HomeInfo';
            } else if (this.currentTab === 'HomeInfo') {
                this.currentTab = 'SuccessPage'
            }
        },
        previousTab() {
            if (this.currentTab === 'HomeInfo') {
                this.currentTab = 'PersonalInfo';
            }
        }
    }
}
</script>

<template>
    <div class="wrapper">
        <Header/>

        <div>
            <div class="container">
                <h3 class="sub-heading">
                    Mortgage Application
                </h3>
            </div>

            <div class="border-line"></div>

            <div class="my-5">
                <div class="container">
                    <div class="row gx-lg-5">
                        <div class="col-md-3 col-lg-4">
                            <div class="row">
                                <div class="col-12">
                                    <div class="d-flex mb-40">
                                        <div class="circular-info-icon">
                                            <span class="active">1</span>
                                        </div>
                                        <div class="circular-info-content align-self-end">
                                            <p class="text__black">
                                               Personal Information
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="d-flex mb-40">
                                        <div class="circular-info-icon">
                                            <span class="bg-gray" :class="{active: currentTab === 'HomeInfo' || currentTab === 'SuccessPage'}">2</span>
                                        </div>
                                        <div class="circular-info-content align-self-end">
                                            <p :class="{text__black: currentTab === 'HomeInfo' || currentTab === 'SuccessPage'}">
                                                Your home choice
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <keep-alive>
                            <component :is="currentTab" :form="formData" @next="nextTab" @previous="previousTab"/>
                        </keep-alive>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<style scoped>

</style>