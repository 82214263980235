<script>

import { validateMoneyFormat, requiredField } from '@/utils/validation';

export default {
    name: "PersonalInfo",
    props: {
        form: {
            type: Object
        }
    },
    data() {
        return {
            maritalStatuses: [
                {value: 'single', label: 'Single'},
                {value: 'married', label: 'Married'}
            ],
            workStatuses: [
                {value: 'yes', label: 'Yes'},
                {value: 'no', label: 'No'}
            ],
            fieldErrors: {
                last_name: '',
                first_name: '',
                city: '',
                canada_address_1: '',
                canada_address_2: '',
                postal_code: '',
                phone_number: '',
                gross_annual_income: '',
                net_annual_income: '',
                monthly_net_pay: '',
                min_price: '',
                max_price: '',
                is_self_employed: '',
                dob: '',
                marital_status: '',
                home_use: '',
                state: '',
                home_type: '',
                email: ''
            },
        }
    },
    methods: {
        nextTab() {
            this.$emit('next', 'next')
        },
        previousTab() {
            this.$emit('previous', 'previous')
        },
        validateForm() {
            this.fieldErrors = {}; // Clear previous error messages

            if (
                this.requiredField(this.form.first_name, 'first_name') &&
                this.requiredField(this.form.last_name, 'last_name') &&
                this.requiredField(this.form.canada_address_1, 'canada_address_1') &&
                this.requiredField(this.form.canada_address_2, 'canada_address_2') &&
                this.requiredField(this.form.postal_code, 'postal_code') &&
                this.requiredField(this.form.city, 'city') &&
                this.requiredField(this.form.phone_number, 'phone_number') &&
                this.requiredField(this.form.gross_annual_income, 'gross_annual_income)') &&
                this.requiredField(this.form.net_annual_income, 'net_annual_income') &&
                this.requiredField(this.form.marital_status, 'marital_status') &&
                this.requiredField(this.form.is_self_employed, 'is_self_employed') &&
                this.requiredField(this.form.monthly_net_pay, 'monthly_net_pay') &&
                this.requiredField(this.form.dob, 'dob') &&
                this.requiredField(this.form.email, 'email') &&
                this.validateAlphanumericInput() &&
                this.validateEmail()
            ) {
                this.nextTab();
            }

            this.requiredField(this.form.first_name, 'first_name');
            this.requiredField(this.form.last_name, 'last_name');
            this.requiredField(this.form.canada_address_1, 'canada_address_1');
            this.requiredField(this.form.canada_address_2, 'canada_address_2');
            this.requiredField(this.form.city, 'city');
            this.requiredField(this.form.phone_number, 'phone_number');
            this.validateMoneyInput(this.form.gross_annual_income, 'gross_annual_income');
            this.validateMoneyInput(this.form.net_annual_income, 'net_annual_income');
            this.requiredField(this.form.marital_status, 'marital_status');
            this.requiredField(this.form.is_self_employed, 'is_self_employed');
            this.validateMoneyInput(this.form.monthly_net_pay, 'monthly_net_pay');
            this.requiredField(this.form.dob, 'dob');
            this.validateEmail();
            this.validateAlphanumericInput();
        },
        requiredField(value, fieldName) {
            if (requiredField(value)) {
                this.fieldErrors[fieldName] = '';
                return true;
            } else {
                this.fieldErrors[fieldName] = 'This field is required.';
                return false;
            }
        },
        validateEmail() {
            const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
            if (!emailRegex.test(this.form.email)) {
                this.fieldErrors.email = 'Please enter a valid email address';
                return false;
            }
            this.fieldErrors.email = ''
            return true
        },
        validateMoneyInput(value, fieldName) {
            if (validateMoneyFormat(value)) {
                this.fieldErrors[fieldName] = '';
                return true;
            } else {
                this.fieldErrors[fieldName] = 'Please enter a valid amount';
                return false;
            }
        },
        validateAlphanumericInput() {
            const alphanumericRegex = /^[a-zA-Z0-9]*$/;
            if (alphanumericRegex.test(this.form.postal_code)) {
                this.fieldErrors.postal_code = '';
                return true;
            } else {
                this.fieldErrors.postal_code = 'Please enter a valid postal code';
                return false
            }
        },
    }
}
</script>

<template>
    <div class="col-md-9 col-lg-8">
        <form>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-input">
                        <input placeholder="First name *"
                               type="text"
                               v-model="form.first_name"
                               @input="requiredField(form.first_name, 'first_name')"
                               required>
                        <span class="text-danger fs-11" v-if="fieldErrors.first_name">{{ fieldErrors.first_name }}</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-input">
                        <input placeholder="Last name *"
                               type="text"
                               v-model="form.last_name"
                               @input="requiredField(form.last_name, 'last_name')"
                               required>
                        <span class="text-danger fs-11" v-if="fieldErrors.last_name">{{ fieldErrors.last_name }}</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-input">
                        <input placeholder="Canadian address 1 *"
                               type="text"
                               v-model="form.canada_address_1"
                               @input="requiredField(form.canada_address_1, 'canada_address_1')"
                               required>
                        <span class="text-danger fs-11" v-if="fieldErrors.canada_address_1">{{ fieldErrors.canada_address_1 }}</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-input">
                        <input placeholder="Canadian address 2 *"
                               type="text"
                               v-model="form.canada_address_2"
                               @input="requiredField(form.canada_address_2, 'canada_address_2')"
                               required>
                        <span class="text-danger fs-11" v-if="fieldErrors.canada_address_2">{{ fieldErrors.canada_address_2 }}</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-input">
                        <input placeholder="City *"
                               type="text"
                               v-model="form.city"
                               @input="requiredField(form.city, 'city')"
                               required>
                        <span class="text-danger fs-11" v-if="fieldErrors.city">{{ fieldErrors.city }}</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-input">
                        <input placeholder="Postal code *"
                               type="text"
                               v-model="form.postal_code"
                               @input="validateAlphanumericInput"
                               required>
                        <span class="text-danger fs-11" v-if="fieldErrors.postal_code">{{ fieldErrors.postal_code }}</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-input">
                        <input placeholder="Date of birth YYYY-MM-DD *"
                               type="text"
                               v-model="form.dob"
                               onfocus="(this.type = 'date')"
                               onblur="(this.type = 'text')"
                               required>
                        <span class="text-danger fs-11" v-if="fieldErrors.dob">{{ fieldErrors.dob }}</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-input">
                        <select v-model="form.is_self_employed" required >
                            <option value="">Are you self-employed?</option>
                            <option v-for="item in workStatuses" :key="item.value">{{ item.label }}</option>
                        </select>
                        <span class="text-danger fs-11" v-if="fieldErrors.is_self_employed">{{ fieldErrors.is_self_employed }}</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-input">
                        <input placeholder="Gross Annual Income (CAD) *"
                               type="text"
                               v-model="form.gross_annual_income"
                               @input="validateMoneyInput(form.gross_annual_income, 'gross_annual_income')"
                               required>
                        <span class="text-danger fs-11" v-if="fieldErrors.gross_annual_income">{{ fieldErrors.gross_annual_income }}</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-input">
                        <input placeholder="Net Annual Income (CAD) *"
                               type="text"
                               v-model="form.net_annual_income"
                               @input="validateMoneyInput(form.net_annual_income, 'net_annual_income')"
                               required>
                        <span class="text-danger fs-11" v-if="fieldErrors.net_annual_income">{{ fieldErrors.net_annual_income }}</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-input">
                        <input placeholder="Monthly net pay (CAD) *"
                               type="text"
                               v-model="form.monthly_net_pay"
                               @input="validateMoneyInput(form.monthly_net_pay, 'monthly_net_pay')"
                               required>
                        <span class="text-danger fs-11" v-if="fieldErrors.monthly_net_pay">{{ fieldErrors.monthly_net_pay }}</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-input">
                        <input placeholder="Other Annual Income"
                               type="text"
                               v-model="form.other_annual_income">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-input">
                        <input placeholder="Email *"
                               type="email"
                               v-model="form.email"
                               @input="validateEmail"
                               required>
                        <span class="text-danger fs-11" v-if="fieldErrors.email">{{ fieldErrors.email }}</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-input">
                        <input placeholder="Phone number *"
                               type="number"
                               v-model="form.phone_number"
                               @input="requiredField(form.phone_number, 'phone_number')"
                               required>
                        <span class="text-danger fs-11" v-if="fieldErrors.phone_number">{{ fieldErrors.phone_number }}</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-input">
                        <select v-model="form.marital_status" required >
                            <option value="">Marital Status *</option>
                            <option v-for="item in maritalStatuses" :key="item.value">{{ item.label }}</option>
                        </select>
                        <span class="text-danger fs-11" v-if="fieldErrors.marital_status">{{ fieldErrors.marital_status }}</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-input">
                        <input placeholder="Social Insurance Number (SIN)"
                               type="number"
                               v-model="form.sin">
                    </div>
                </div>

                <div class="d-flex justify-content-between mt-5">
                    <div class="arrow-icon">
                        <router-link to="/mortgage" class="btn">
                            <i class="bi bi-arrow-left-short fs-3 v-align_inherit"></i>
                        </router-link>
                    </div>
                    <div class="text-end">
                        <button type="button" class="btn btn__primary" @click="validateForm">
                            Continue <i class="ms-2 bi bi-arrow-right v-align_inherit"></i>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<style scoped>

</style>