<script>
import {appName} from "@/utils/variables";
export default {
    name: "SuccessPage",
    data() {
        return {appName}
    }
}
</script>

<template>
    <div class="col-md-9 col-lg-8">
        <div class="row">
            <h2 class="mortgage_title">
                Thank You!
            </h2>
            <h4 class="mortgage_sub-title">
                You're now just a step away from settling into your new home, and we couldn't be more thrilled for you!
            </h4>
            <p>
                In the next few minutes, you'll receive an email confirmation of your successful mortgage payment
                application, and the next steps. If you don't see it in your inbox, don't forget to check your spam
                or promotions folder – it might be hiding there.
            </p>

            <div class="text__right mt-5">
                <router-link to="/" class="btn btn__primary">
                    Explore {{ appName }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>