<template>
    <div class="wrapper">
        <Header/>

        <div>
            <div class="container">
                <h3 class="sub-heading">
                    Mortgage Payment
                </h3>
            </div>

            <div class="border-line"></div>

            <div class="my-5">
                <div class="container">
                    <div class="row gx-5">
                        <div class="col-md-6">
                            <img src="@/assets/images/mortgage-img.png" alt="mortgage" class="img-fluid w-100">
                            <div>
                                <h4 class="mortgage_sub-title">
                                    Explore the Benefits of {{ appName }}
                                </h4>
                                <p class="text__black fs-16">
                                    <span class="fw-500">Competitive Exchange Rates:</span> Access favourable exchange
                                    rates for your currency conversion needs.
                                </p>
                                <p class="text__black fs-16">
                                    <span class="fw-500">Flexible Payment Options:</span> Choose the payment schedule
                                    that suits your financial situation.
                                </p>
                                <p class="text__black fs-16">
                                    <span class="fw-500">Reliable Service:</span>  Trust in a company with a proven
                                    track record of helping individuals like you.
                                </p>
                                <p class="text__black fs-16">
                                    <span class="fw-500">24/7 Support:</span> Our dedicated customer support team is
                                    available to assist you at any time.
                                </p>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-5 mt-5 mt-md-0">
                            <h2 class="mortgage_title">
                                Thinking of buying a home in Nigeria?
                            </h2>
                            <p class="text__black fs-16">
                                Congratulations on taking the exciting step of home ownership in Nigeria. At {{ appName }},
                                we understand that managing mortgage payments from overseas can be a complex task.
                                That's why we've created a tailored solution to make the process seamless and secure.
                            </p>
                            <h4 class="mortgage_sub-title">
                                Hassle-Free Currency Exchange
                            </h4>
                            <p class="text__black mb-40 fs-16">
                                One of the first challenges you might encounter when managing mortgage payments overseas
                                is currency exchange. With fluctuating exchange rates, it's essential to secure the best
                                deal. {{ appName }} provides you with a hassle-free way to convert your funds into the
                                Nigerian Naira at competitive rates, ensuring that your mortgage payments are
                                cost-effective.
                            </p>

                            <h4 class="mortgage_sub-title">
                                Convenient Payment Options
                            </h4>
                            <p class="text__black mb-40 fs-16">
                                Our platform offers a variety of payment options to fit your unique needs. Whether you
                                prefer monthly, quarterly, or custom payment schedules, we've got you covered. We ensure
                                your payments arrive on time, allowing you to enjoy a stress-free homeownership experience.
                            </p>
                            <h4 class="mortgage_sub-title">
                                Secure and Transparent
                            </h4>
                            <p class="text__black mb-40 fs-16">
                                Your financial security is our top priority. We employ state-of-the-art encryption
                                technology to protect your transactions and personal information. You can have peace of
                                mind knowing your mortgage payments are made securely.
                            </p>
<!--                            <h4 class="mortgage_sub-title">-->
<!--                                Get pre-qualified to receive your Pre-Approval Certificate-->
<!--                            </h4>-->
<!--                            <ul class="text__black mb-40">-->
<!--                                <li>Lorem ipsum dolor sit amet consectetur. Non orci gravida turpis blandit.</li>-->
<!--                                <li>amet consectetur. Non orci gravida turpis blandit.</li>-->
<!--                                <li>ipsum dolor sit amet consectetur. Non orci gravida turpis blandit. Consectetur non orci-->
<!--                                    gravida turpis blandit.</li>-->
<!--                                <li>Lorem ipsum dolor sit amet consectetur. Non orci gravida turpis blandit. Lorem ipsum dolor-->
<!--                                    sit amet consectetur. Non orci gravida turpis blandit.</li>-->
<!--                            </ul>-->
                            <div class="text__right">
                                <router-link to="/mortgage/apply" class="btn btn__primary">
                                    Continue <i class="ms-2 bi bi-arrow-right v-align_inherit"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>

import Footer from "@/components/elements/Footer.vue";
import Header from "@/components/elements/Header.vue";
import { appName } from "@/utils/variables";

export default {
    name: "Mortgage",
    components: {Footer, Header},
    data() {
        return {
            appName: appName
        }
    }
}
</script>

<style scoped>

</style>